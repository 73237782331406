<template>
  <div class="login">
    <div class="login_header">
      <div class="logo"></div>
    </div>
    <div class="login_title">二连浩特市文件批办系统</div>
    <div class="login_center">
      <div class="login-main">
        <div class="main_title">
          <span>账号登录</span>
        </div>
        <el-form ref="formRef" label-width="0px"
          @keyup.enter="submitForm(formRef)"
          :model="ruleForm" :rules="rules">
          <el-form-item prop="username">
            <el-input
              class="input_item"
              placeholder="请输入用户名"
              v-model="ruleForm.username" clearable
            />
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              class="input_item"
              placeholder="请输入密码"
              v-model="ruleForm.password" show-password
            />
          </el-form-item>

          <el-form-item>
            <el-button
              class="login_btn"
              type="danger"
              :loading="loading" :disabled="loading"
              @click="submitForm(formRef)">
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, toRefs, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { setCookie } from '@/utils'
import { login } from '@/api'
import { useRouter } from 'vue-router'
const router = useRouter()

const state = reactive({
  ruleForm: {
    username: '',
    password: ''
  },
  loading: false
})
const rules = {
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, max: 23, message: '密码为6-23位', trigger: 'blur' }
  ]
}

const { ruleForm, loading } = toRefs(state)

const formRef = ref(null)

function submitForm (formRef) {
  if (!formRef) return
  formRef.validate((valid) => {
    if (valid) {
      console.log('success submit!')
      LoginApi()
    } else {
      console.log('error submit!')
      return false
    }
  })
}

// 请求登陆接口
function LoginApi () {
  state.loading = true
  login(state.ruleForm).then(res => {
    console.log('登录', res.user)
    state.loading = false
    if (res.user.status === 1) {
      setCookie('token', `Bearer ${res.token}`)
      setCookie('realname', res.user.realname)
      setCookie('roles', res.user.roles)
      setCookie('departmentId', res.user.department_id)
      router.replace({
        path: '/',
        query: {}
      })
    } else {
      ElMessage({
        type: 'error',
        message: '帐号异常，请联系管理员'
      })
    }
  }).catch(err => {
    state.loading = false
    ElMessage({
      type: 'error',
      message: err.toString()
    })
  })
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  background: #BD192D url('../../assets/images/login_bg2.png') no-repeat top right;
  background-size: 892px 191px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  .login_header {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    .logo {
      width: 360px;
      height: 82px;
      background: url('../../assets/images/logo.png') no-repeat center center;
      background-size: cover;
      margin-left: 12%;
    }
  }
  .login_title {
    width: 100%;
    height: 72px;
    font-size: 28px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #961323;
  }
  .login_center {
    width: 100%;
    flex: 1;
    background: url('../../assets/images/login_bg.png') no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-main {
      width: 540px;
      height: 450px;
      box-sizing: border-box;
      padding: 24px 70px;
      background: #FFFFFF;
      box-shadow: 0px 5px 20px 0px rgba(249,159,70,0.4600);
      border-radius: 12px;
      .main_title {
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 64px;
        & span{
          font-size: 24px;
          font-family: SourceHanSansSC-Medium, SourceHanSansSC;
          font-weight: 500;
          color: #111111;
          line-height: 36px;
          border-bottom: 4px solid #DE2625;
        }
      }
      .input_item {
        width: 400px;
        height: 56px;
      }
      .login_btn {
        width: 400px;
        height: 48px;
        margin-top: 50px;
        background: #DE2625;
        box-shadow: 0px 6px 16px -2px rgba(222,38,37,0.5000);
        border-radius: 24px;
        font-size: 18px;
      }
    }
  }
}
</style>

<style>
.login .el-input__wrapper {
  border-radius: 28px;
  padding: 27px;
  background: #F5F5F5;
  border: none;
}
.login .el-input__inner {
  font-size: 20px;
  font-family: SourceHanSansSC-Medium, SourceHanSansSC;
  font-weight: 500;
  color: #111111;
}
</style>
